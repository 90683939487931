import React from 'react';
import Section from '../components/Section';
import ReturnToSiteLink from '../components/ReturnToSiteLink';
import Layout from "../components/Layout"
import SectionOverview from "../components/SectionOverview"
import Seo from "../components/Seo"
import SectionBanner from "../components/SectionBannerSmall"

function DEIAStatement(){

    return (
      <Layout>
        <Seo title="Bixal DEIA Statement" />
        <div>
          <SectionBanner
            bgImage="assets/images/img-whatwedo.jpg"
            title="Bixal DEIA Statement"
          />
          <Section id={"deia"} indent={true} spacing={30} constrain>

          <SectionOverview align={'left'} title={''} divider={true}>
          <h2>You belong at Bixal. </h2>
          </SectionOverview>

            <div className="deia-container">
              <div className="policy">
                <div className="title">
                  
                </div>
                <div className="info">
                <p>Diversity, Equity, Inclusion, and Accessibility (DEIA) guide everything we do at Bixal. </p>
                <p>We are committed to creating a culture that values our human differences by elevating and including our diverse perspectives, voices, and experiences throughout Bixal.</p>
                <p>Our team members' diversity comes from around the world, with a range of personal and professional experiences that strengthen our work and fuel our progress toward creating a better world.</p>
                <p>As a woman and minority-owned organization, our workforce is 50 percent minority. We challenge ourselves to do more to ensure an exceptional work environment and service experience for our clients and the people we serve.</p>
                <p>At Bixal, we encourage you to bring your authentic self to work daily, and we value everyone as they are.</p>

                <p>Being “People Absolutely First” is a Bixal core value. Here are a few ways our passion for DEIA principles shows up:
                  <ul style={{listStyleType: 'initial', paddingInlineStart: '20px', margin: 'initial',}}>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Establishing a dedicated DEIA Council of peers that works closely with our executive leadership team.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Sharing company demographic statistics, both internally and externally.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Fostering equity and commitment to inclusive roles.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Pledging our support to the Coalition for Racial and Ethnic Equity (REE) in Development (<a href="https://creedinaction.org/">CREED</a>), a coalition of global development organizations based in the United States who are working to instill REE in international development.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Matching donations to support employee-driven social causes.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Celebrating Bixal’s diverse community through educational events and activities.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Investing in efforts to collect objective data and fresh insights.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Engaging DEIA experts to provide actionable data and fresh insights on how we can continually improve.</li>
                    <li style={{listStyle: "initial", paddingInlineStart: "initial", padding: "initial", margin: "initial",}}>Welcoming your perspective through DEIA focus groups and surveys.</li>
                  </ul>
                </p>
                </div>
              </div>
            </div>
            <ReturnToSiteLink
              location={`/`}
              position={"bottom"}
            ></ReturnToSiteLink>
          </Section>
        </div>
      </Layout>
    )
}

export default DEIAStatement;
